import { Link } from "gatsby";
import * as React from "react";
import { Card, Container, Row } from "react-bootstrap";
import Layout from "../components/Layout";
import { graphql } from "gatsby"

export default function AboutusPage({ data }) {
  const TextElemt = data.TextElemt.nodes
  const ImageElemt = data.ImageElemt.nodes
  const SecondImageElemt = data.SecondImageElemt.nodes
  const HeadingResource = data.HeadingResource.nodes
  const TextSecondElemt = data.TextSecondElemt.nodes


  return (
    <Layout>
      <main>
        <Container fluid={true}>
          <section className="">
            <Container className="me-0">
              <Row>
                <div className="col-xl-7 col-lg-7 col-md-12 pb-4 pt-5  pe-5">
                  <div className="col-xl-11 col-lg-11 col-md-12 ms-auto mb-5">
                    <Card className="card-about">
                      <Card.Body className="px-5 py-4">
                        {TextElemt.map((node, index) => {
                          return (
                            <>
                              <h5 className="bogo-par fw-500 lh-32 fw-airlight ">
                                {node.FirstTextWidgetDescription}
                              </h5>
                            </>
                          )
                        })}

                      </Card.Body>
                    </Card>
                  </div>
                  <div className="col-xl-11 col-lg-11 col-md-12 ms-auto">
                    <Card className="card-about">
                      <Card.Body className="px-5 py-4">
                        {TextSecondElemt.map((node, index) => {
                          return (
                            <>
                              <h5 className="bogo-par fw-500 lh-32 fw-airlight ">
                                {node.SecondTextWidgetDescription}
                              </h5>
                            </>
                          )
                        })}

                      </Card.Body>
                    </Card>
                  </div>
                </div>
                <div className="col-xl-5 col-lg-5 col-md-12 pe-0 ms-auto vector wb-right d-none d-sm-block">
                  <div className="row text-right">
                    <div className="col-xl-6 col-lg-6 col-md-6 pe-0">
                      {ImageElemt.map((node, index) => {
                        return (
                          <>
                            <img
                              src={node.MainImage}
                              alt="Iphone"
                              height="578"
                              className="nav-link pe-0"
                            />
                          </>
                        )
                      })}

                    </div>

                    <div className="col-xl-6 col-lg-6 col-md-6 pe-0">
                      {SecondImageElemt.map((node, index) => {
                        return (
                          <>
                            <img
                              src={node.SecondImage}
                              alt="Iphone"
                              height="578"
                              className="nav-link pe-0"
                            />
                          </>
                        )
                      })}
                    </div>
                  </div>
                </div>
                <div className="col-xl-5 col-lg-5 col-md-12 pe-0 ms-auto vector wb-right d-block d-sm-none">
                  <div className="d-flex text-right">
                    <div className="col-xl-6 col-lg-6 col-md-6 pe-0">
                      <img
                        src="../img/Iphone-1.svg"
                        alt="Iphone"
                        className="nav-link pe-0 become-bogo"
                      />
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 pe-0">
                      <img
                        src="../img/Iphone-2.svg"
                        alt="Iphone"
                        className="nav-link pe-0 become-bogo"
                      />
                    </div>
                  </div>
                </div>

              </Row>
            </Container>
            <Container fluid={true} className="py-5 px-0">
              <section className="bg-light my-5 px-0 custom-h">
                <Row>
                  {HeadingResource.map((node, index) => {
                    return (
                      <>
                        <div className="col-xl-4 col-lg-4 col-md-12 ps-0">
                          <img
                            src={node.MultipleTitleWidgeImage}
                            className="footer-about"
                            alt="bg"
                          />
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 pt-xl-5 pt-lg-5 mt-xl-4 mt-lg-4 pt-5 mb-5 mt-xl-3 mt-lg-3 pe-xl-5 pe-2 ps-4 ps-xl-3">
                          <h3 className="fw-bold mb-5">{node.MultipleTitleWidgetFirstTitle}</h3>
                          <h5 className="bogo-par fw-500 lh-32 fw-airlight  mb-5 fw-airlight pe-5 me-5 pe-5 me-3">
                            {node.MultipleTitleWidgetFirstDescription}
                          </h5>
                          <Link to={node.MultipleTitleWidgetFirstCTALink} className="fs-18 link-color pb-2">
                            {node.MultipleTitleWidgetFirstCTAText}{" "}
                            <img
                              src="../img/arrow-right.svg"
                              alt="arrow right"
                              className="ms-3"
                            />
                          </Link>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 pt-xl-5 pt-lg-5 mt-xl-4 mt-lg-4 pt-5 mb-5  pe-xl-5 pe-2 ps-4 ps-xl-3">
                          <h3 className="fw-bold mb-5">{node.MultipleTitleWidgetSecondTitle}</h3>
                          <h5 className="bogo-par fw-500 lh-32 fw-airlight  mb-5 fw-airlight pe-5 me-5 me-3">
                            {node.MultipleTitleWidgetSecondDescription}
                          </h5>
                          <Link to={node.MultipleTitleWidgetSecondCTALink} className="fs-18 link-color pb-2">
                            {node.MultipleTitleWidgetSecondCTAText}{" "}
                            <img
                              src="../img/arrow-right.svg"
                              alt="arrow right"
                              className="ms-3"
                            />
                          </Link>
                        </div>
                      </>
                    )
                  })}

                </Row>
              </section>
            </Container>
          </section>
        </Container>
      </main>
    </Layout>
  );
}
export const query = graphql`
  query {
   TextElemt: allAboutJson(filter: {FirstTextWidgetDescription: {ne: null}}) {
      nodes {
        FirstTextWidgetDescription
      }
    }
    TextSecondElemt: allAboutJson(filter: {SecondTextWidgetDescription: {ne: null}}) {
      nodes {
        SecondTextWidgetDescription
      }
    }
    ImageElemt:allAboutJson(filter: {MainImage: {ne: null}}) {
      nodes {
        MainImage
      }
    }
    SecondImageElemt:allAboutJson(filter: {SecondImage: {ne: null}}) {
      nodes {
        SecondImage
      }
    }
    HeadingResource:allResourceJson(filter: {MultipleTitleWidgetFirstTitle: {ne: null}}) {
      nodes {
        MultipleTitleWidgeImage
        MultipleTitleWidgetFirstCTALink
        MultipleTitleWidgetFirstDescription
        MultipleTitleWidgetFirstCTAText
        MultipleTitleWidgetFirstTitle
        MultipleTitleWidgetSecondCTALink
        MultipleTitleWidgetSecondCTAText
        MultipleTitleWidgetSecondDescription
        MultipleTitleWidgetSecondTitle
      }
    }
  }
`